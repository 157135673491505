$primary-color: white !default;
$link-color: grey !default;
$navbar-font-color: black !default;
$footer-font-color: black !default;
$button-raised-background: black !default;

@import "materialize";

nav,
footer {
  .brand-logo {
    .brand-logo-content {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 600;
      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }
}

.hero {
  width: 100%;
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2.8rem;
    font-weight: 800;
  }

  p {
    font-size: 1.2rem;
  }

  .hero-content {
    max-width: 420px;
  }
}

.legal {
  max-width: 720px;
  margin: auto;
  padding: 20px;

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

.post {
  max-width: 720px;
  margin: auto;
  padding: 20px;
  white-space: pre-line;
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

.post-html {
  max-width: 720px;
  margin: auto;
  padding: 20px;
  h2 {
    font-size: 2rem;
    margin-bottom: 4rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

.download-buttons {
  margin-top: 32px;
  margin-bottom: 16px;
  img {
    margin: 5px;
    width: 150px;
    height: 44px;
    border-radius: 5px;
  }
}

.open-in-browser {
  margin-bottom: 32px;
  a {
    display: flex;
    align-items: center;
    padding: 0;
    span {
      text-transform: none;
      font-size: 16px;
    }
  }
  i {
    margin-left: 12px;
  }
}

section {
  margin: 96px auto;
  img {
    width: 65%;
  }
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
  }
  p {
    font-size: 1.2rem;
  }
  .section-content {
    margin: 16px;
    h2,
    p {
      max-width: 420px;
      margin: 32px auto;
    }
  }
}

.contact-us {
  text-align: center;
  margin: 32px auto 96px auto;
  max-width: 420px;
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
  }
  p {
    font-size: 1.2rem;
  }
}

footer {
  padding: 0 0 32px 0;
  background-color: #f3f3f3;
  .row.links {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .brand-logo {
    margin-top: 50px;
    display: block;
  }
  .download-buttons {
    text-align: right;
  }
}

.main-container {
  min-height: calc(100vh - 250px);
}

@media screen and (max-width: 600px) {
  section {
    .valign-wrapper {
      display: block;
    }
    .section-content {
      text-align: center;
    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    margin-top: 32px;
    h1 {
      font-size: 2.5rem;
      margin-top: 1rem;
    }
  }
  .download-buttons {
    margin-top: 32px;
    img {
      width: 130px;
      height: 40px;
    }
  }
  section {
    h2 {
      font-size: 2rem;
      font-weight: 800;
    }
    p {
      font-size: 1.2rem;
    }
  }
  footer {
    .download-buttons {
      text-align: center;
    }
  }
}
